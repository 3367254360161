import * as React from "react"
import Layout from '../components/layout';
import { graphql, useStaticQuery } from "gatsby";
import SubCard from "../components/sub-card";
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../components/seo";
import { LogNavigator } from "../components/log-navigator";


const LogPage = () => {
  const data = useStaticQuery(graphql`
    query{
      allMdx (
        sort: {
          fields: [frontmatter___date]
          order: DESC
        }
      ){
        edges {
          node {
            frontmatter {
              date(formatString: "YYYY/MM/DD")
              title
              slug
              tag
              thumbnail {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            body
          }
        }
      }
    }
  `);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const recentDate = new Date(data.allMdx.edges[0].node.frontmatter.date);
  const recentMonth = recentDate.getMonth();
  const recentYear = recentDate.getFullYear();

  const top = data.allMdx.edges.find(edge => {
    return edge.node.frontmatter.slug === "log"
  })

  const others = data.allMdx.edges.filter(edge => {
    const postDate = new Date(edge.node.frontmatter.date);
    
    // Check if there are entries from the current month.
    if (postDate.getMonth() === currentMonth && postDate.getFullYear() === currentYear) {
      return edge.node.frontmatter.slug !== "log" && edge.node.frontmatter.tag === "お酒記";
    } else if (postDate.getMonth() === recentMonth && postDate.getFullYear() === recentYear) {
      // If no entries from the current month, display entries from the most recent month.
      return edge.node.frontmatter.slug !== "log" && edge.node.frontmatter.tag === "お酒記";
    } else {
      return false;
    }
  })



  return (
    <Layout>
      <SEO title="無濾過生原酒ブログ　お酒記" description="美味しいお酒との出会いの記録でございます。"/>
      <div>
        <div className="my-4 p-4 lg:mx-4">
          <div className="p-4 bg-white rounded-lg markdown">
            <MDXRenderer>{top.node.body}</MDXRenderer>
          </div>
          <LogNavigator />
        </div>
        <div className="lg:flex lg:flex-wrap">
          {
            others.map(other => {
              return <SubCard 
                title={other.node.frontmatter.title} 
                date={other.node.frontmatter.date}
                src={other.node.frontmatter.thumbnail.childImageSharp.fluid.src}
                tag={other.node.frontmatter.tag} 
                slug={other.node.frontmatter.slug}/>
            })
          }
        </div>
      </div>
    </Layout>
  )
}

export default LogPage
