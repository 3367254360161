import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState, useEffect } from "react";
// month navigator component

export const LogNavigator = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              date(formatString: "YYYY/MM/DD")
              title
              slug
              tag
              thumbnail {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            body
          }
        }
      }
    }
  `);

  // 一意の年月の組み合わせを取得
  const months = new Set();

  data.allMdx.edges.forEach((edge) => {
    const yearMonth = edge.node.frontmatter.date.slice(0, -3);
    months.add(yearMonth);
  });

    return (
        <div className="flex mt-2 flex-wrap">
            {
                [...months].map((month) => {
                    return (
                        <Link to={`/log/${month}`} className="p-2 m-2 bg-white rounded-lg markdown">
                            {month}
                        </Link>
                    )
                })
            }
        </div>
    );
};
